import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../service/api/api.service';
declare function initialize(): any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  constructor(public api : ApiService){
    api.update_data_from_token()
  }
  ngOnInit(): void {
    initialize()
  }
  menu: any = {
    titre: "Menu",
    items: [
      { libelle: "AbonnementUtilisateur", path: "/home/abonnement_utilisateur" },
      { libelle: "Avis", path: "/home/avis" },
      { libelle: "Categorie", path: "/home/categorie" },
      { libelle: "Commande", path: "/home/commande" },
      { libelle: "DetailCommande", path: "/home/detail_commande" },
      { libelle: "ImageProduit", path: "/home/image_produit" },
      { libelle: "Paiement", path: "/home/paiement" },
      { libelle: "Produit", path: "/home/produit" },
      { libelle: "Promotion", path: "/home/promotion" },
      { libelle: "PromotionProduit", path: "/home/promotion_produit" },
      { libelle: "Role", path: "/home/role" },
      { libelle: "SousCategorie", path: "/home/sous_categorie" },
      { libelle: "TypeAbonnement", path: "/home/type_abonnement" },
      { libelle: "Utilisateur", path: "/home/utilisateur" },
      { libelle: "Variante", path: "/home/variante" }
    ]
  }
  sidebarVisible = false;

  toggleSidebar() {
    this.sidebarVisible = !this.sidebarVisible;
  }
}
