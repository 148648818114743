<div class="layout-wrapper layout-content-navbar">
      <div class="layout-container">
            <!-- Menu -->
            <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme">
                  <div class="d-flex align-items-center justify-content-center demo app-brand" id="logo_minoterie_bloc">
                        <img src="assets/logo.jpeg.jpg" width="80%" height="80%" alt="" id="logo_minoterie">
                        <a href="javascript:void(0);"
                              class="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
                              <i class="bx bx-chevron-left bx-sm align-middle"></i>
                        </a>
                  </div>
                  <div class="menu-inner-shadow"></div>
                  <ul class="menu-inner py-1">
                        <ng-container *ngFor="let un_menu of api.menu">
                              <li class="menu-header small text-uppercase">
                                    <span class="menu-header-text">{{un_menu.menu_header}}</span>
                              </li>
                              <!-- Dashboard -->
                              <li *ngFor="let un_item of un_menu.items" class="menu-item" [routerLinkActive]="'active'">
                                    <a [routerLink]="[un_item.path]" class="menu-link">
                                          <i class="menu-icon tf-icons bx" [class]="un_item.icone"></i>
                                          <div data-i18n="Analytics">{{un_item.text}}</div>
                                    </a>
                              </li>
                        </ng-container>
                  </ul>
            </aside>
            <!-- / Menu -->
            <!-- Layout container -->
            <div class="layout-page">
                  <!-- Navbar -->
                  <nav class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
                        id="layout-navbar">
                        <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
                              <a class="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
                                    <i class="bx bx-menu bx-sm"></i>
                              </a>
                        </div>
                        <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                              <!-- Search -->
                              <div class="navbar-nav align-items-center">
                                    <div class="nav-item d-flex align-items-center">
                                          <i class="bx bx-search fs-4 lh-0"></i>
                                          <input type="text" class="form-control border-0 shadow-none"
                                                placeholder="Rehercher..." />
                                    </div>
                              </div>
                              <!-- /Search -->
                              <ul class="navbar-nav flex-row align-items-center ms-auto">
                                    <li>
                                          <a class="nav-link dropdown-toggle hide-arrow me-2" [routerLink]="['/public/']">Accueil</a>
                                    </li>
                                    <li class="nav-item navbar-dropdown dropdown-user dropdown">
                                          <a class="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);"
                                                data-bs-toggle="dropdown">
                                                <div class="avatar avatar-online">
                                                      <img src="../assets/img/our/avatar.png" alt
                                                            class="w-px-40 h-auto rounded-circle" />
                                                </div>
                                          </a>
                                          <ul class="dropdown-menu dropdown-menu-end">
                                                <li>
                                                      <a class="dropdown-item"
                                                            [routerLink]="['/home/utilisateur/profil']">
                                                            <div class="d-flex">
                                                                  <div class="flex-shrink-0 me-3">
                                                                        <div class="avatar avatar-online">
                                                                              <img src="../assets/img/our/avatar.png"
                                                                                    alt
                                                                                    class="w-px-40 h-auto rounded-circle" />
                                                                        </div>
                                                                  </div>
                                                                  <div class="flex-grow-1">
                                                                        <span class="fw-semibold d-block">{{api.token.token_decoded.taf_data.prenom_utilisateur+"
                                                                              "+api.token.token_decoded.taf_data.nom_utilisateur}}</span>
                                                                        <small class="text-muted">
                                                                              {{"ici le privilege"}}</small>
                                                                  </div>
                                                            </div>
                                                      </a>
                                                </li>
                                                <!-- <li>
                                                      <div class="dropdown-divider"></div>
                                                </li> -->
                                                <!-- <li>
                                                      <a class="dropdown-item" [routerLink]="['/home/profil']">
                                                            <i class="bx bx-user me-2"></i>
                                                            <span class="align-middle">Mon profil</span>
                                                      </a>
                                                </li>
                                                <li>
                                                      <a class="dropdown-item clique">
                                                            <i class="bx bx-download me-2"></i>
                                                            <span class="align-middle">Mettre à jour</span>
                                                      </a>
                                                </li> -->
                                                <li>
                                                      <div class="dropdown-divider"></div>
                                                </li>
                                                <li>
                                                      <a class="dropdown-item" [routerLink]="['/public/deconnexion']">
                                                            <i class="bx bx-power-off me-2"></i>
                                                            <span class="align-middle">Déconnexion</span>
                                                      </a>
                                                </li>
                                          </ul>
                                    </li>
                              </ul>
                        </div>
                  </nav>
                  <!-- / Navbar -->
                  <!-- Content wrapper -->
                  <div class="content-wrapper">
                        <!-- Content -->
                        <div class="container flex-grow-1 container-p-y">
                              <router-outlet></router-outlet>
                        </div>
                        <!-- / Content -->
                        <!-- Footer -->
                        <footer class="content-footer footer bg-footer-theme">
                              <div
                                    class="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                                    <div class="mb-2 mb-md-0">
                                          © made by
                                          <a href="https://jant.tech" target="_blank"
                                                class="footer-link fw-bolder text-warning">JantTech</a>
                                    </div>
                                    <div>
                                          <a href="https://jant.tech/#a-propos-de-nous" class="footer-link me-4"
                                                target="_blank">A propos de nous</a>
                                    </div>
                              </div>
                        </footer>
                        <!-- / Footer -->
                        <div class="content-backdrop fade"></div>
                  </div>
                  <!-- Content wrapper -->
            </div>
            <!-- / Layout page -->
      </div>
      <!-- Overlay -->
      <div class="layout-overlay layout-menu-toggle"></div>
</div>